import { Button, Container, Input } from './styles'
import { FiSearch } from 'react-icons/fi'
import { FormEvent } from 'react'
import { useCustomContext } from '../../context';

export default function SearchBar() {

    const { setText } = useCustomContext();

    function getFormValues(event: FormEvent<HTMLFormElement>) {
        setText("");
        event.preventDefault();
        const value = (document.getElementById("inputText") as HTMLInputElement).value;

        if (value) {
            setText(value);
        }
    }

    return (
        <Container onSubmit={(e) => { getFormValues(e) }}>
            <Input placeholder='Começe por aqui, digite um frase ou um texto!' id='inputText'></Input>
            <Button type='submit'><FiSearch /></Button>
        </Container>
    )
}