import { useEffect, useState } from 'react';
import { useCustomContext } from '../../context'
import { Container, Content, RightArea, Title, Link, Unused } from './styles'
import { VscGithubAlt } from 'react-icons/vsc'

export default function Header() {

    const { text } = useCustomContext();
    const [hasValue, setHasValue] = useState(false);

    useEffect(() => {

        if (text !== "") {
            setHasValue(true)
        } else {
            setHasValue(false)
        }

    }, [text])

    return (
        <Container hasValue={hasValue}>
            <Content>
                <Unused></Unused>
            </Content>
            <Content>
                <Title>Análise de sentimentos</Title>
            </Content>
            <Content>
                <RightArea>
                    <Link href="https://github.com/gabeps2/sentiment-analisys-react" target="_blank"> <VscGithubAlt /></Link>
                </RightArea>
            </Content>
        </Container>
    )
}