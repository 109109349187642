import styled, { keyframes } from 'styled-components';

interface ContainerProps {
	sentiment: String
}

const backgroundAnimation = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

`;

export const Container = styled.div<ContainerProps>`
		
		/* ${props => props.sentiment === 'POSITIVE' ? 'background : linear-gradient(-45deg, #00ff87, #60efff);'
		: props.sentiment === 'NEGATIVE' ? 'background: linear-gradient(-45deg, #ff1b6b, #e60b09);'
			: props.sentiment === 'NEUTRAL' ? 'background: linear-gradient(-45deg, #ff930f, #fff95b);'
				: props.sentiment === 'MIXED' ? 'background: linear-gradient(-45deg, #ff1b6b, #45caff);'
					: 'background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);'} */
					
		background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: ${backgroundAnimation} 15s ease infinite;
        height: 100vh;

		transition: 1s;
`;

export const Content = styled.div``;