import { Container, Content } from './styles'
import SearchBar from '../../components/SearchBar'
import Header from '../../components/Header'
import Sentiments from '../../components/Sentiments'
import { useCustomContext } from '../../context'
import { useEffect } from 'react'


export default function Home() {

    const { primarySentiment } = useCustomContext();

    useEffect(()=>{

        console.log(primarySentiment)

    },[primarySentiment])

    return (
        <Container sentiment={primarySentiment}>
            <Content>
                <Header />
                <SearchBar></SearchBar>
                <Sentiments></Sentiments>
            </Content>
        </Container>
    )


}