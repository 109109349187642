import styled, { css, keyframes } from "styled-components";

interface SentimentProps {
    size: number;
    color?: string;
}

interface ContainerProps {
    hasValue: boolean
}

const respire = (color: string) => keyframes`
  50% {box-shadow: 0px 0px 100px ${color}};
}`;

export const Container = styled.div<ContainerProps>`

    display: grid;
    
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    background-color: transparent;
    width: 100vw;
    height: 33rem;
    
    opacity:  ${props => props.hasValue ? "1" : "0"};

    margin-top: 5rem;
    
    transition: opacity 0.5s;

    @media (max-width: 414px) {
        display: flex;
        flex-direction: column;
    }

    `

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const SentimentText = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    font-size: 2rem;
    color: white;

    @media (max-width: 414px) {
        margin-top: 2rem;
    }

`;

export const Sentiment = styled.div<SentimentProps>`

    width: ${props => props.size + "rem"};
    height:${props => props.size + "rem"};
    border-radius: ${props => props.size + "rem"};

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;

    svg{
        width: 100%;
        height: 100%;
    }

    @media (max-width: 414px) {
        width: ${props => (props.size - 5) + "rem"};
    height:${props => (props.size - 5) + "rem"};
    border-radius: ${props => (props.size - 5) + "rem"};
    }

    ${props => props.color ? css`animation: ${respire(props.color)} 3s infinite;` : ""}

`

export const LoadingContainer = styled.div<ContainerProps>`

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 100vw;
    height: 33rem;

    opacity:  ${props => props.hasValue ? "1" : "0"};

    margin-top: 5rem;

    transition: opacity 0.5s;

    img{
        height: 80%;
        background-color: transparent;
    }

`;