import styled from 'styled-components';

interface HeaderProps {
    hasValue: Boolean;
}

export const Container = styled.div<HeaderProps>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100vw;
    align-items: center;
    justify-content: space-around;

    transition: margin 1s;
    margin-bottom: ${props => props.hasValue ? '5rem' : '23rem'};

    @media (max-width: 414px) {
        margin-bottom: ${props => props.hasValue ? '5rem' : '35rem'};
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 1rem;

    @media (max-width: 414px) {
    }
`;

export const Title = styled.p`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 35rem;
    height: 4.5rem;
    border-radius: 10rem;
    font-size: 1.5rem;

    background-color: transparent;
    border: 1px solid #ffffff;
    
    @media (max-width: 414px) {
        height: 5rem;
        font-size: 1.8rem;
    }
    
`;

export const RightArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
`;

export const Link = styled.a`

    width: 4.5rem;
    height: 4.5rem;
    border-radius: 4.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-size: 1.5rem;

    background-color: transparent;
    border: 1px solid #ffffff;

    margin: .5rem;

    transition: background-color color .3s;

    :hover{
        background-color: #ffffff;
        color: #23a6d5
    }

    transition: .3s;

    svg{
        width: 2.5rem;
        height: 2.5rem;
    }

`;

export const Unused = styled.div``;