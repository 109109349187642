import React, { useContext, useState } from "react";
import { createContext } from "react";

interface CustomContextData {
    text: String
    setText: React.Dispatch<React.SetStateAction<String>>
    primarySentiment: string
    setPrimarySentiment: React.Dispatch<React.SetStateAction<string>>
}

const CustomContext = createContext<CustomContextData>({} as CustomContextData);

const CustomContextProvider: React.FC = ({ children }) => {
    const [text, setText] = useState<String>("");
    const [primarySentiment, setPrimarySentiment] = useState<string>("")

    return (
        <CustomContext.Provider value={{ text, setText, primarySentiment, setPrimarySentiment }}>
            {children}
        </CustomContext.Provider>
    );
}

function useCustomContext(): CustomContextData {
    const context = useContext(CustomContext)
    return context;
}

export { CustomContextProvider, useCustomContext }