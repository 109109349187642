import { ReactElement, useEffect, useState } from 'react'
import { useCustomContext } from '../../context'
import { api } from '../../services/api'
import { Container, Content, Sentiment, SentimentText, LoadingContainer } from './styles'

import {
    BsFillEmojiSmileFill,
    BsFillEmojiNeutralFill,
    BsFillEmojiDizzyFill,
    BsFillEmojiFrownFill
} from 'react-icons/bs'

import _ from 'underscore';

import LoadingImg from '../../assets/loading.svg'

const sentimentEnum: { [key: string]: ReactElement } = {
    "Positive": <BsFillEmojiSmileFill />,
    "Negative": <BsFillEmojiFrownFill />,
    "Neutral": <BsFillEmojiNeutralFill />,
    "Mixed": <BsFillEmojiDizzyFill />,
}

const colorEnum: { [key: string]: string } = {
    "Positive": "#00ff87",
    "Negative": "#e60b09",
    "Neutral": "#fff95b",
    "Mixed": "#e73c7e",
}

interface SentimentAnalysisInterface {
    sentiment: {
        value: string
    },
    sentimentScore: SentimentScore
}

interface SentimentScore {
    positive: number,
    negative: number,
    neutral: number,
    mixed: number
}

interface PayloadData {
    text: String,
    languageCode: String;
}

interface ValuesData {
    name: string,
    value: string
}


export default function Sentiments() {

    const { text, setPrimarySentiment } = useCustomContext()

    const [payload, setPayload] = useState<PayloadData>()
    const [values, setValues] = useState<ValuesData[]>([]);

    useEffect(() => {
        setPayload({
            text: text,
            languageCode: "pt"
        })
    }, [text])

    useEffect(() => {

        async function getSentiment() {
            if (payload?.text) {
                setValues([]);
                const apiResponse = await api.post<SentimentAnalysisInterface>("/SentimentAnalysis", payload)

                setPrimarySentiment(apiResponse.data.sentiment.value)

                const { positive, negative, neutral, mixed } = apiResponse.data.sentimentScore;

                const values = [
                    { name: "Positive", value: (positive * 100).toFixed(2) },
                    { name: "Negative", value: (negative * 100).toFixed(2) },
                    { name: "Neutral", value: (neutral * 100).toFixed(2) },
                    { name: "Mixed", value: (mixed * 100).toFixed(2) }
                ];

                const sortedValues = _.sortBy<ValuesData[]>(values, "value").reverse();

                setValues(sortedValues)
            }
        }

        getSentiment();

    }, [payload, setPrimarySentiment])

    return (
        <>
            {values.length !== 0 && text !== "" ? (
                <Container hasValue={values.length !== 0}>
                    <>
                        <div></div>
                        <Content>
                            <SentimentText>{values[1].name + " : " + values[1].value + "%"}</SentimentText>
                            <Sentiment size={20}> {sentimentEnum[values[1].name]} </Sentiment>
                        </Content>
                        <Content>
                            <SentimentText>{values[0].name + " : " + values[0].value + "%"}</SentimentText>
                            <Sentiment size={25} color={colorEnum[values[0].name]}> {sentimentEnum[values[0].name]} </Sentiment>
                        </Content>
                        <Content>
                            <SentimentText>{values[2].name + " : " + values[2].value + "%"}</SentimentText>
                            <Sentiment size={15}> {sentimentEnum[values[2].name]} </Sentiment>
                        </Content>
                        <Content>
                            <SentimentText>{values[3].name + " : " + values[3].value + "%"}</SentimentText>
                            <Sentiment size={10}> {sentimentEnum[values[3].name]} </Sentiment>
                        </Content>
                    </>
                </Container>

            ) : (
                <LoadingContainer hasValue={text !== ""}>
                    <img src={LoadingImg} alt="Loading" />
                </LoadingContainer>
            )}
        </>
    )
}