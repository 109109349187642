import styled from 'styled-components';

export const Container = styled.form`

    width: 100%;
    height: 5rem;

    display: flex;

    align-items:  center;
    justify-content: center;

`;

export const Input = styled.input`

    width: 50rem;
    height: 5rem;
    border-radius: 10rem;

    color: #fff;
    font-size: 1.5rem;
    padding: 2rem;

    border: 1px solid #ffffff;
    background-color: transparent;

    transition: .5s;

    ::placeholder{
        color: #fff;
        opacity: 0.6;
    }

    :hover{
        box-shadow: rgba(255, 255, 255, 0.7) 0px 10px 36px 0px, rgba(255, 255, 255, 0.5) 0px 0px 0px 1px;
    }

    @media (max-width: 414px) {
        height: 6.5rem;
        font-size: 1.9rem;
    }
`;
export const Button = styled.button`
    width: 5rem;
    height: 5rem;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 2rem;

    background-color: transparent;
    border: 1px solid #ffffff;

    transition: 0.3s;


    svg{
        color: #ffffff;
        width: 2.5rem;
        height: 2.5rem;
    }

    :hover{
        box-shadow: rgba(255, 255, 255, 0.7) 0px 10px 36px 0px, rgba(255, 255, 255, 0.5) 0px 0px 0px 1px;
    }

    @media (max-width: 414px) {
        width: 6.5rem;
        height: 6.5rem;

        svg{
            width: 3rem;
            height: 3rem;
        }
    }
`;