import Home from './pages/home'
import { CustomContextProvider } from './context'

function App() {
  return (
    <div>
      <CustomContextProvider>
        <Home></Home>
      </CustomContextProvider>
    </div>
  );
}

export default App;
